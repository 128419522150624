// https://loading.io/css/
import React from 'react'
import LoadersType from "../Loaders/LoadersType"

// import './assets/css/Loaders.css'

const Loaders = ({type}) => (
  <div className="Loaders">
    <LoadersType type={type} />
  </div>
)

export default Loaders
