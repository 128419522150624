import React from "react";
import { Router } from "@reach/router";
import { Container } from "reactstrap";

import LanguageContext from "./../state/context/LanguageContext";
import SearchList from "../components/Search/SearchList";

const SearchTemplate = ({ pageContext }) => {
  const currentPath =
    pageContext && pageContext.searchPath
      ? pageContext.searchPath
      : "recherche";
  return (
    <Container>
      <LanguageContext.Consumer>
        {({ language, translation }) => (
          <Router>
            <SearchList
              currentLanguage={language}
              path={currentPath}
              mySearch=""
            />
            <SearchList
              currentLanguage={language}
              path={`${currentPath}/:mySearch`}
            />
          </Router>
        )}
      </LanguageContext.Consumer>
    </Container>
  );
};

export default SearchTemplate;
