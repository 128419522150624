import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import Config from "../../config/Config.json";
import Config_local from "../../config/Config_local.json";
import SearchQueryConfig from "./SearchQueryConfig.json";
import SearchForm from "./SearchForm";
import SearchFilter from "./SearchFilter";
import SearchListItem from "./SearchListItem";
import Pagination from "../Pagination/Pagination";
import Loaders from "../Loaders/Loaders";
import i18n from "./../../i18n/i18n.json";

// import base64 from "base-64"

class SearchList extends Component {
  constructor(props, context) {
    super(props, context);

    //On récupère l'url de ES de l'environnement
    if (Config_local.esServer) {
      Config.esServer = Config_local.esServer;
    }

    //On initialise la Query de base pour ES
    this.SearchQuery = SearchQueryConfig.queryDefault;
    //On initialise les filtres de base pour ES
    this.SearchFilter = SearchQueryConfig.queryFilter;
    //On sauvegarde le filtrage sur tous les types de contenus
    this.SearchFilterFull =
      SearchQueryConfig.queryDefault.query.bool.must[0].bool.should;
    //On initialise les filtres actifs
    this.SearchFilterActive = [];

    //On définit les variables à surveiller
    this.state = {
      loading: false,
      mysearch: "*",
      SearchFilterLaunch: false,
      SearchFilterActive: [],
      SearchFilterClassActive: "search-list search-filter-all",
      totals: false,
      page: 0,
      posts: 0
    };

    //on bloque la boucle d'une touche laissée enfoncée
    this.onKeyDown = false;
  }

  componentDidMount() {
    // On détecte si on a un mot clé dans l'url
    if (
      this.props.mySearch !== false &&
      this.props.mySearch &&
      this.props.mySearch.trim().length > 0
    ) {
      this.setState({
        mysearch: this.props.mySearch.trim(),
        loading: true
      });

      this.launchSearch(this.props.mySearch.trim());
    }
  }

  componentWillUnmount() {
    this.SearchFilterActive = [];
    this.setState({
      SearchFilterClassActive: "search-list search-filter-all",
      loading: true,
      totals: false,
      page: 0,
      posts: 0
    });
    this.SearchQuery.query.bool.must[0].bool.should = this.SearchFilterFull;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps &&
      nextProps.mySearch &&
      this.props.mySearch &&
      nextProps.mySearch !== this.props.mySearch
    ) {
      this.setState({
        mysearch: nextProps.mySearch.trim(),
        loading: true,
        totals: false,
        page: 0,
        posts: 0
      });

      this.launchSearch(nextProps.mySearch.trim());
      return false;
    }
    // return a boolean value
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps &&
      prevProps.mySearch &&
      this.props.mySearch &&
      prevProps.mySearch !== this.props.mySearch
    ) {
      this.setState({
        mysearch: prevProps.mysearch.trim(),
        loading: true,
        totals: false,
        page: 0,
        posts: 0
      });

      this.launchSearch(prevProps.mysearch.trim());
    }
  }

  launchSearch(forceMySearch = null, forcePage = null) {
    const { currentLanguage = "fr" } = this.props;

    let mysearch = this.state["mysearch"];
    if (forceMySearch !== null) {
      mysearch = forceMySearch;
    }
    let mypage = this.state["page"] - 1;
    if (forcePage !== null) {
      mypage = forcePage - 1;
    }
    if (mypage < 0) {
      mypage = 0;
    }
    const SearchFilterActive = this.state["SearchFilterActive"];
    if (mysearch) {
      if (
        (mysearch && (!this.onKeyDown && mysearch.length > 2)) ||
        (mysearch && mysearch === "*") ||
        SearchFilterActive.length > 0
      ) {
        const that = this;
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[0].match.post_title.query = mysearch.trim();
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[1].match.post_excerpt.query = mysearch.trim();
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[2].match.post_content.query = mysearch.trim();
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[3].match.post_content_filtered.query = mysearch.trim();
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[4].match.terms.query = mysearch.trim();
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[5].match.post_keywords.query = mysearch.trim();
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[6].match.post_team_status.query = mysearch.trim();
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[7].regexp.post_title.value = ".*" + mysearch.trim() + ".*";
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[8].regexp.post_excerpt.value = ".*" + mysearch.trim() + ".*";
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[9].regexp.post_content.value = ".*" + mysearch.trim() + ".*";
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[10].regexp.post_content_filtered.value = ".*" + mysearch.trim() + ".*";
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[11].regexp.terms.value = ".*" + mysearch.trim() + ".*";
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[12].regexp.post_keywords.value = ".*" + mysearch.trim() + ".*";
        this.SearchQuery.query.bool.must[1].bool.should[0].bool.must[0].bool.should[13].regexp.post_team_status.value = ".*" + mysearch.trim() + ".*";

        this.SearchQuery.query.bool.must[2].bool.should[0].match_phrase.language_slug = currentLanguage;

        const today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        this.SearchQuery.query.bool.must[3].bool.should[0].bool.must[0].range.post_date.lte = yyyy + '-' + mm + '-' + dd + ' 23:59:59';
        this.SearchQuery.query.bool.must[3].bool.should[1].bool.must[0].range.date_begin.lte = yyyy + '-' + mm + '-' + dd + ' 23:59:59';

        today.setMonth(today.getMonth() - 6);
        dd = String(today.getDate()).padStart(2, '0');
        mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        yyyy = today.getFullYear();

        this.SearchQuery.query.bool.must[3].bool.should[0].bool.must[0].range.post_date.gte = yyyy + '-' + mm + '-' + dd + ' 00:00:00';
        this.SearchQuery.query.bool.must[3].bool.should[1].bool.must[0].range.date_begin.gte = yyyy + '-' + mm + '-' + dd + ' 00:00:00';

        this.SearchQuery.from = this.SearchQuery.size * mypage;

        // let username = 'elasticsearchdevinouit';
        // let password = 'wd8u5g2gJE74XDG';

        let headers = new Headers();

        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append(
          "tokenesinouit",
          "spS4brB5EYX2tu8exUPFZDXvAr0ZD7u9whzxPfGqGvGWGcRMDqHxH04axVSj1k0a6TlJTAagi4Sz3yKg5NNPi0F7m2LUSFvZCCQYzMdTS3WXxAXtATBbWVhXwMpSS79PycfnwdabDVsSWttOAWkyCK67DWXq5g1HYTwH8toyF9HyZwrOmttRnYQSf4qnzUJJWYyTAAbvBrdegXFweIdt5IRX0S5GL8ARk3Kz30GWR3tQpxNwrmid9nSe7SQxopoVC7Kl9gR5zQY7ijpeohaEqJZsvg3hN400000iGm8nKB0vKL2i9nPFaLIzVXV6c4RKuWBwzXrBOiCfyAtjyZAe7dpEIuqz82cIh80wiRaVCdOQ9YtE355IqgpiOytsSKTtw6HuWaGjopqjRe9pzixWJWGhLRGPB9iZRe1TP78HuiPdWKHUGWxB5MHroMoTbFqfyLXo9kk4djQA2GuAsTLI8VYOCDwCAcfJOPMtJIwa356KHK8Ma4VCFYgWqJ2sZtT77pOszI8deVGb9M1DXWD9nPhsGJSqDt4K8TRzhcVmgn8sUamx8TFyPa1E06Gh2QUCyXTfjOP0LzSgAdb2gaOohuSOfYibjfoE3aCSnevQAflmGoCqsFphlYNCTzYQhdO2"
        );
        // headers.append('Authorization', 'Basic' + base64.encode(username + ":" + password));
        this.SearchQuery.size = 20;
        // console.log(this.SearchQuery)
        const body = "" + JSON.stringify(this.SearchQuery) + "";
        fetch(Config.esServer, {
          // mode: 'no-cors',
          headers: headers,
          method: "POST",
          body: body
        })
          .then(response => {
            if (response.status && response.status > 200) {
              console.warn(response);
            } else {
              return response.json();
            }
          })
          .then(results => {
            that.setState({
              loading: false,
              totals: ( (results && results.hits && results.hits.total) ? results.hits.total : 0 ),
              posts: ( (results && results.hits && results.hits.hits) ? results.hits.hits : 0 ) 
            });
          })
          .catch(function(error) {
            console.error("error", error);
          });
      }
      else {            
        this.setState({
          loading: false,
          totals: 0,
          posts: 0
        });
      }
    }
  }

  searchOnChange = (e) => {
    e.preventDefault();
    return false;
  }

  searchOnKeyDown = ({ target }) => {
    if (!this.onKeyDown) {
      this.onKeyDown = true;
    }
  }

  searchOnKeyUp = (e) => {
    const { currentLanguage = "fr" } = this.props;
    const languageSearch =
      i18n[currentLanguage] && i18n[currentLanguage].searchPage
        ? i18n[currentLanguage].searchPage
        : "recherche";

    this.onKeyDown = false;
    if (e.keyCode === 13) {
      const { value } = e.target;
      window.location = `/${languageSearch}/` + value.trim();
    } else {
      this.onKeyDown = false;
      const { value } = e.target;
      this.setState({
        mysearch: value.trim()
      });
    }
  }

  searchOnSubmit = (e) => {
    e.preventDefault();
  }

  searchFormFilters = ({ target }) => {
    const filterClick = target.getAttribute("data-filter-tag");
    const findiIt = this.SearchFilterActive.indexOf(filterClick);
    if (filterClick !== null) {
      if (findiIt !== -1) {
        this.SearchFilterActive.splice(findiIt, 1);
      } else {
        this.SearchFilterActive.push(filterClick);
      }
      let listClass = "search-list";
      if (this.SearchFilterActive.length === 0 || filterClick === "all") {
        this.SearchFilterActive = [];
        this.setState({
          SearchFilterClassActive: "search-list search-filter-all",
          loading: true,
          totals: false,
          page: 0,
          posts: 0
        });
      } else {
        this.SearchFilterActive.forEach(function(element) {
          listClass = listClass + " search-filter-" + element;
        });
        this.setState({
          SearchFilterClassActive: listClass,
          loading: true,
          totals: false,
          page: 0,
          posts: 0
        });
      }
      if (this.SearchFilterActive.length > 0) {
        this.SearchQuery.query.bool.must[0].bool.should = [];
        const that = this;
        this.SearchFilterActive.forEach(function(element) {
          that.SearchQuery.query.bool.must[0].bool.should.push(
            that.SearchFilter[element]
          );
        });
      } else {
        this.SearchQuery.query.bool.must[0].bool.should = this.SearchFilterFull;
      }
      this.setState({
        SearchFilterLaunch: true,
        loading: true,
        totals: false,
        page: 0,
        posts: 0
      });
      this.launchSearch(this.state.mysearch.trim());
    }
  }

  searchOnClickPaginate = (e) => {
    e.preventDefault();
    const dataNumPage = Number(e.target.getAttribute("data-num-page"));
    this.setState({
      mysearch: this.state.mysearch.trim(),
      loading: true,
      totals: false,
      page: dataNumPage,
      posts: 0
    });
  }

  renderLoader = () => {
    return (
      <div className="loader">
        <Loaders type="ripple" />
      </div>
    );
  };

  renderHeader = () => {
    //this.state['totals'] ? : <h1>Aucun résultat</h1>
    let content;
    if (this.state.loading) {
      content = "Recherche en cours";
    } else {
      if (this.state["totals"] !== false) {
        let keyword = "";
        if (this.state["mysearch"] !== "*") {
          keyword = "«" + this.state["mysearch"] + "»";
        }
        let result = "Résultat";
        if (this.state["totals"] > 1) {
          result = "Résultats";
        }
        // content = this.state['totals'] + " " + result + " pour votre recherche " + keyword
        content =
          this.state["totals"] + " " + result + " de recherche pour " + keyword;
        if (this.state["totals"] === 0) {
          content =
            "Désolé, votre recherche ne correspond à aucun de nos contenus.";
        }
      }
    }
    return (
      <div className="search-title">
        <h1>{content}</h1>
      </div>
    );
  };

  renderList = () => {
    return this.state.posts.map((post, index) => {
      return (
        <div className="search-list" key={index}>
          <SearchListItem postIndex={index} postItem={post} />
        </div>
      );
    });
  };

  renderPagination = () => {
    let totals;
    // let offset;
    // let limit;
    let currentPage;
    if (!this.state.loading) {
      if (this.state.page !== false) {
        totals = this.state.totals;
        // offset = this.state.offset;
        // limit = this.state.limit;
        currentPage = this.state.page;
      }
    }
    return (
      <div>
        <Pagination
          offset={Config.searchPagination.offset}
          limit={Config.searchPagination.limit}
          totals={totals}
          currentPage={currentPage}
          OnClickPaginate={this.searchOnClickPaginate}
        />
      </div>
    );
  };

  render = () => {
    let classListeName = this.state["SearchFilterClassActive"];
    let content;
    if (this.state.loading) {
      this.launchSearch();
      content = this.renderLoader();
    } else {
      content = this.state.posts.length ? this.renderList() : "";
    }
    return (
      <Container className="search-list-container">
        <Row>
          <Col tag="aside" lg="4" md="12">
            <div className="anchors-sidebar h-100 pr-5">
              <div className="bg-light h-100 search-list-container-left" />
            </div>
          </Col>
          <Col
            tag="article"
            md="12"
            lg="8"
            className="py-md-5 page-content position-relative"
          >
            <div className="search-list-container-right">
              <div id="search-list" className={classListeName}>
                {this.renderHeader()}
                <SearchForm
                  onSubmit={this.searchOnSubmit}
                  onSearchOnKeyUp={this.searchOnKeyUp}
                  placeholder="Mot recherché"
                  type="text"
                />
                {this.props && this.props.currentLanguage === "fr" && (
                  <SearchFilter
                    onClick={this.searchFormFilters}
                    totalResult={this.state["totals"]}
                  />
                )}

                {content}
                {this.state.page !== false && this.renderPagination()}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };
}

export default SearchList;
