import React, { Component } from 'react'

import SearchItemCatName from './Items/SearchItemCatName'
import SearchItemDate from './Items/SearchItemDate'
import SearchItemDates from './Items/SearchItemDates'
import SearchItemTitle from './Items/SearchItemTitle'
import SearchItemExcerpt from './Items/SearchItemExcerpt'
import SearchItemTeamStatus from './Items/SearchItemTeamStatus'
// import SearchItemScore from './Items/SearchItemScore'

class SearchListItem extends Component {
	getPostTitle = () => {
		if (this.props.postItem.highlight) {
			if (this.props.postItem.highlight.post_title) {
				return String(this.props.postItem.highlight.post_title)
			} else if (this.props.postItem._source.post_title) {
				return String(this.props.postItem._source.post_title)
			}
		} else if (this.props.postItem._source.post_title) {
			return String(this.props.postItem._source.post_title)
		}
		return ''
	}

	getPostThumbnail = () => {
		if (this.props.postItem._source.post_thumbnail) {
			return String(this.props.postItem._source.post_thumbnail)
		}
		return ''
	}

	getPostImage = () => {
		if (this.props.postItem._source.post_image) {
			return String(this.props.postItem._source.post_image)
		}
		return ''
	}

	getPostOriginalImage = () => {
		if (this.props.postItem._source.post_original_image) {
			return String(this.props.postItem._source.post_original_image)
		}
		return ''
	}

	getPostType = () => {
		if (this.props.postItem._source.post_type) {
			return "search-list-item search-list-item-"+String(this.props.postItem._source.post_type)
		}
		return "search-list-item search-list-item-"
	}

	render = () => {
		return (
			<div key={this.props.postIndex} className={this.getPostType()}>

				<div className="search-list-item-head">
					<SearchItemCatName postItem={this.props.postItem} />
					<SearchItemDates postItem={this.props.postItem} />
				</div>

				{ this.getPostImage() ? <p className="search-list-item-picture"><img src={this.getPostImage()} alt={this.getPostTitle().replace(/(<([^>]+)>)/ig, '')} /></p>  : '' }

				<div className={ this.getPostImage() ? "search-list-item-content search-list-item-content-withpic" : "search-list-item-content" } >
					<SearchItemDate postItem={this.props.postItem} />
					<SearchItemTitle postItem={this.props.postItem} />
					<SearchItemExcerpt postItem={this.props.postItem} />
					<SearchItemTeamStatus postItem={this.props.postItem} />
				</div>
			</div>
		)
	}
}

export default SearchListItem;
