import React from 'react';

const SearchForm = ({onSubmit, onSearchOnKeyUp, ...rest}) => (
	<form id="search-form" className="search-form" onSubmit={onSubmit}>
		<div className="search-form-group">
			<input id="search-form-text" className="search-form-text" onKeyUp={onSearchOnKeyUp} {...rest} />
			<span className="search-form-submit" >
				<input id="search-form-submit-input" className="search-form-submit-input" type="submit" value="Go" />
			</span>
		</div>
	</form>
)

export default SearchForm;
