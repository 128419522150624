import React, { Component } from 'react'

class Loaders extends Component {
	renderEllipsis = () => {
		return (
			<div className="wrapper-loader">
				<div className="wrapper-loader-content">
					<div className="ellipsis-loader-item">
						<div></div><div></div><div></div><div></div>
					</div>
				</div>
			</div>
		)
	}

	renderSpinner = () => {
		return (
			<div className="wrapper-loader">
				<div className="wrapper-loader-content">
					<div className="spinner-loader-item">
						<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
					</div>
				</div>
			</div>
		)
	}

	renderRipple = () => {
		return (
			<div className="wrapper-loader">
				<div className="wrapper-loader-content">
					<div className="ripple-loader-item">
						<div></div><div></div>
					</div>
				</div>
			</div>
		)
	}

	render = () => {
		let contentLoader = ""
		const {type} = this.props
		switch (type) {
			case "ellipsis":
				contentLoader = this.renderEllipsis()
				break;

			case "spinner":
				contentLoader = this.renderSpinner()
				break;

			case "ripple":
				contentLoader = this.renderRipple()
				break;

			default:
				contentLoader = this.renderRipple()
				break;
		}
		return (
			<div>
				{contentLoader}
			</div>
		)
	}
}

export default Loaders