import React, { Component } from 'react'
import SearchFilterItem from "./SearchFilterItem";
import SearchPopularSearch from "./SearchPopularSearch"

class SearchFilter extends Component {

	render = () => {
		return (
			<div className="search-filter">
				<p className="search-filter-label">Filtrer par : </p>
				<ul className="search-filter-list" onClick={this.props.onClick} onKeyPress={this.props.onClick} role="presentation">
					<SearchFilterItem filterTag="all" filterLabel="Tous" className="search-filter-item search-filter-all"/>
					<SearchFilterItem filterTag="pages" filterLabel="Contenus" className="search-filter-item search-filter-pages"/>
					<SearchFilterItem filterTag="annuaires" filterLabel="Annuaires" className="search-filter-item search-filter-annuaires"/>
					<SearchFilterItem filterTag="events" filterLabel="Evénements" className="search-filter-item search-filter-events"/>
					<SearchFilterItem filterTag="actus" filterLabel="Actus Roubaixxl" className="search-filter-item search-filter-actus"/>
				</ul>
				{this.props.totalResult === 0 ? <SearchPopularSearch /> : ''}
			</div>
		)
	}
}

export default SearchFilter;
