import React, { Component } from 'react'

class SearchItemDate extends Component {
	getPostDate = () => {
		if (this.props.postItem._source.post_date) {
			let dateBegin = new Date(this.props.postItem._source.post_date)
            if ( this.props.postItem._source.post_type === 'events-openagenda' ) {
                dateBegin = new Date(this.props.postItem._source.date_begin_timestamp * 1000)
            }
			return new Intl.DateTimeFormat('fr-FR', {day: '2-digit', month: 'long', year: 'numeric'}).format(dateBegin)
		}
		return ''
	}

	getPostDatesClass = () => {
		if (this.props.postItemMini) {
			return 'search-list-item-date search-list-mini-item-date'
		}
		return 'search-list-item-date'
	}

	render = () => {
		return (
			<>
				{ this.getPostDate() ? <p className={this.getPostDatesClass()}>{}<span>{this.getPostDate()}</span></p>  : '' }
			</>
		)
	}
}

export default SearchItemDate;
